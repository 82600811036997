import { SiFacebook, SiInstagram } from "@icons-pack/react-simple-icons";
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import VimeoClass from "@u-wave/react-vimeo";
import { format } from "date-fns";
import { ChevronRight } from "lucide-react";
import { Suspense } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GoogleMap } from "../components/google-map";
import { ImageGallery } from "../components/image-gallery";
import { config } from "../config.server";
import { contentService } from "../database/services/content.service";
import { scheduleService } from "../database/services/schedule.service";
import { imageService } from "../services/image.service";
import indexStyleUrl from "../styles/index.css?url";
import { getSite } from "../utils/auth.util";
import { getTimeString } from "../utils/date.util";

const Vimeo =
  "default" in VimeoClass
    ? (VimeoClass.default as typeof VimeoClass)
    : VimeoClass;

export const meta: MetaFunction = () => [{ title: "Chatterbox Jazz Club" }];

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: indexStyleUrl },
];

type IndexLoader = typeof loader;
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const site = await getSite(request);
  const contents = await contentService.getByPage(site.hostname, "/");
  const carousel = await imageService.getMany(
    imageService.getCategories(site.hostname).carousel.prefix,
  );
  const schedule = await scheduleService.getMany(site.hostname, true);
  return {
    site,
    contents,
    carousel,
    schedule,
    mapsKey: config.GOOGLE_MAPS_API_KEY,
  };
};

export default function IndexRoute() {
  const { site, contents, carousel, schedule, mapsKey } =
    useLoaderData<IndexLoader>();

  return (
    <div>
      {/* Banner */}
      <div className="section-banner w-full flex justify-center items-center">
        <Suspense>
          <div
            className="text-center uppercase text-white"
            style={{ fontSize: "42px" }}
          >
            {contents["Banner text"]}
          </div>
        </Suspense>
      </div>

      {/* Address / Introduction */}
      <Row className="section section-primary">
        <Col xs={12} sm={10} lg={9} xl={6}>
          <Col xs={12} sm={12} xxl={{ offset: 2, span: 8 }}>
            <div className="w-full flex flex-col items-center pt-14 pb-14 text-center">
              <div className="font-bold">
                {contents.Address.split("\n").map((line) => (
                  <div key={line}>{line}</div>
                ))}
              </div>
              {contents.Introduction.split("\n\n").map((section) => (
                <div className="mb-6" key={section}>
                  {section.split("\n").map((line) => (
                    <div key={line}>{line}</div>
                  ))}
                </div>
              ))}
              <div className="mt-12">
                <Button className="h-14">
                  <Link to="/about">Find out more</Link>
                </Button>
              </div>
            </div>
          </Col>
        </Col>
      </Row>

      {/* Image gallery */}
      <Row className="section section-gallery">
        <Col xs={12} sm={10}>
          <ImageGallery autoScroll images={carousel} />
        </Col>
      </Row>

      {/* Music schedule */}
      <Row className="section section-schedule section-primary">
        <div className="section-title">{contents["Schedule title"]}</div>
        <Col xs={12} sm={10}>
          {schedule.map((row) => (
            <Row key={row.start} className="schedule-row">
              <Col xs={3}>
                <div className="text-base">{format(row.start, "EEE")}</div>
                <div className="text-3xl schedule-date">
                  {format(row.start, "MMM")}
                  <br />
                  {format(row.start, "do")}
                </div>
              </Col>
              <Col xs={6} className="schedule-artist">
                {row.artist.name}
              </Col>
              <Col xs={3}>
                {getTimeString(row.start)} - {getTimeString(row.end)}
              </Col>
            </Row>
          ))}
          <Link className="schedule-more-link" to="/events">
            <div className="schedule-more flex items-center space-x-1">
              <span>More Events</span>
              <ChevronRight size={18} />
            </div>
          </Link>
        </Col>
      </Row>

      {/* Video */}
      <Row className="section section-video">
        <div className="section-title">{contents["Video title"]}</div>
        <Col xs={12} md={10} className="flex justify-center">
          <Vimeo video={contents["Video URL"]} className="vimeo" />
        </Col>
      </Row>

      {/* Social */}
      <Row className="section section-social section-primary">
        <div className="section-title">{contents["Social title"]}</div>
        <Col xs={10} className="flex justify-center">
          <a href={contents["Facebook URL"]}>
            <SiFacebook fill="white" />
          </a>
          <a href={contents["Instagram URL"]}>
            <SiInstagram fill="white" />
          </a>
        </Col>
      </Row>

      <Row className="m-0 section-map-contact">
        {/* Map */}
        <Col xs={12} lg={6} className="section section-map">
          <GoogleMap
            apiKey={mapsKey}
            placeId={contents["Google Maps location"]}
          />
        </Col>
        {/* Contact us */}
        <Col
          xs={12}
          lg={6}
          className="section section-contact flex justify-center"
        >
          <div style={{ width: "70%" }}>
            <div className="section-title">{contents["Contact title"]}</div>
            <div>{site.name}</div>
            <div className="contact-address">{contents["Contact address"]}</div>
            <div>
              <a
                className="contact-phone"
                href={`tel:${contents["Contact phone"].replace(/[^0-9]+/g, "")}`}
              >
                {contents["Contact phone"]}
              </a>
            </div>
            <Button className="mt-6" size="lg">
              Let's connect!
            </Button>
          </div>
        </Col>
      </Row>

      {/* Copyright */}
      <Row className="section section-copyright section-primary">
        <Col xs={12} className="text-center">
          Copyright © {new Date().getFullYear()} {site.name} - All Rights
          Reserved.
        </Col>
      </Row>
    </div>
  );
}
