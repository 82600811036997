import React from "react";

export const GoogleMap: React.FC<{ apiKey: string; placeId: string }> = ({
  apiKey,
  placeId,
}) => {
  const params = new URLSearchParams({
    key: apiKey,
    q: placeId,
  });
  return (
    <iframe
      className="maps-iframe"
      style={{ border: 0 }}
      referrerPolicy="no-referrer-when-downgrade"
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/place?${params}`}
      title="Google Maps embed"
    />
  );
};
